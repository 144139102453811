<script>
    import { marked } from 'marked';
    import axios from 'axios';
    import toast from 'svelte-french-toast';
    import { fade } from 'svelte/transition';

    export let announcement;
    export let flash;

    let showConfirmModal = false; // State to toggle the modal

    const appName = 'BMN';

    function formatDate(date) {
        return new Date(date).toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
    }

    $: renderContent = announcement.content_type === 'md' ? marked(announcement.content) : announcement.content;

    async function handleAcknowledge() {
        showConfirmModal = true; // Show the modal
    }

    async function confirmAcknowledge() {
        try {
            const response = await axios.post(
                `/announcements/${announcement.id}/acknowledge`,
                {},
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.content,
                    },
                }
            );

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: 'top-right',
                    duration: 3000,
                    style: 'border-radius: 8px; background: #10b981; color: white; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);',
                });
                setTimeout(() => {
                    inertia.visit('/feed');
                }, 1000); // Delay redirect to show toast
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred.', {
                position: 'top-right',
                duration: 3000,
                style: 'border-radius: 8px; background: #ef4444; color: white; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);',
            });
        } finally {
            showConfirmModal = false; // Close the modal regardless of success or failure
        }
    }

    function cancelAcknowledge() {
        showConfirmModal = false; // Close the modal
    }
</script>

<!-- Flash Messages -->
{#if flash?.success}
    <div class="px-4 pt-4 sm:px-6 lg:px-8">
        <div class="mb-6 rounded-lg border-l-4 border-green-500 bg-green-100 p-4 text-green-700 shadow-sm">
            <p>{flash.success}</p>
        </div>
    </div>
{/if}
{#if flash?.error}
    <div class="px-4 pt-4 sm:px-6 lg:px-8">
        <div class="mb-6 rounded-lg border-l-4 border-red-500 bg-red-100 p-4 text-red-700 shadow-sm">
            <p>{flash.error}</p>
        </div>
    </div>
{/if}

<!-- Main Content -->
<div class="px-4 py-8 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-3xl overflow-hidden rounded-xl bg-white shadow-lg">
        <!-- Header -->
        <div class="bg-gradient-to-r from-blue-600 to-indigo-600 p-6 sm:p-8">
            <h1 class="text-3xl font-bold leading-tight text-white sm:text-4xl">
                {announcement.title || 'New Announcement'}
            </h1>
            <div class="mt-3 space-y-1 text-sm text-blue-100 sm:text-base">
                <p>Posted on {formatDate(announcement.created_at)}</p>
                {#if announcement.validity}
                    <p>Valid until {formatDate(announcement.validity)}</p>
                {/if}
            </div>
        </div>
        <!-- Content -->
        <div class="prose max-w-none p-6 text-base leading-relaxed text-gray-800 sm:p-8 sm:text-lg">
            {@html renderContent}
        </div>
        <!-- Actions -->
        <div
            class="flex flex-col items-center justify-between gap-4 border-t border-gray-200 bg-gray-50 p-6 sm:flex-row sm:p-8"
        >
            {#if announcement.acknowledgement_required}
                <button
                    on:click="{handleAcknowledge}"
                    class="inline-flex transform items-center rounded-xl bg-green-600 px-6 py-3 text-lg font-semibold text-white shadow-lg transition-all duration-300 ease-in-out hover:scale-105 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 focus:ring-opacity-50 active:scale-95"
                >
                    <svg class="mr-3 h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Acknowledge Announcement
                </button>
            {/if}

            <a
            href="/"
            on:click|preventDefault={() => window.location.href = '/'}
            class="font-medium text-blue-500 hover:text-blue-700 hover:underline"
        >
            Back to Home
        </a>

        </div>
    </div>
</div>

<!-- Confirmation Modal -->
{#if showConfirmModal}
    <div
        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        transition:fade="{{ duration: 200 }}"
    >
        <div class="mx-4 w-full max-w-md rounded-xl bg-white p-6 shadow-2xl sm:p-8">
            <h2 class="mb-4 text-xl font-semibold text-gray-800 sm:text-2xl">Confirm Acknowledgment</h2>
            <p class="mb-6 text-gray-600">Are you sure you want to acknowledge this announcement?</p>
            <div class="flex justify-end gap-3">
                <button
                    on:click="{cancelAcknowledge}"
                    class="rounded-lg bg-gray-200 px-4 py-2 font-medium text-gray-700 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                    Cancel
                </button>
                <button
                    on:click="{confirmAcknowledge}"
                    class="rounded-lg bg-green-600 px-4 py-2 font-medium text-white transition duration-150 ease-in-out hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
{/if}

<style>
    .prose :where(p):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
        margin-bottom: 1.25rem;
    }
    .prose :where(h1, h2, h3, h4, h5, h6):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
</style>
